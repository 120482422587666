
import RPHAd from 'rph/molecules/RPHAd/index.vue'

export default {
  name: 'Home',
  components: {
    RPHSearchBlock: () => import('rph/templates/RPHSearchBlock/index.vue'),
    RPHPopularProductBlock: () => import('rph/templates/RPHPopularProductBlock/index.vue'),
    RPHArticlesBlock: () => import('rph/templates/RPHArticlesBlock/index.vue'),
    RPHNearestProductsBlock: () => import('rph/templates/RPHNearestProductsBlock/index.vue'),
    RPHAboutBlock: () => import('rph/templates/RPHAboutBlock/index.vue'),
    RPHAd,
  },
  layout: 'main',
  async mounted() {
    //популярные
    await Promise.all([
      this.$store.dispatch('rph-news/getArticles', {
        expand: 'description,seo',
        page: 1,
        'per-page': 4,
        sort: '-created_at',
      }),
      this.$store.dispatch('rph-categories/getCategories', { expand: 'child' }),
      this.$store.dispatch('rph-news/getArticleCategories', {}),
      this.isAuth
        ? this.$store.dispatch('rph-products/getPersonalRecProducts', {
            params: {
              expand: 'images,prices,extra,producers',
              'per-page': 10,
            },
          })
        : '',
    ])
  },
  async fetch() {
    await Promise.allSettled([
      this.$store.dispatch('rph-main/getMainPage'),
      this.$store.dispatch('rph-banners/getSmallBanners'),
      this.$store.dispatch('rph-products/getPopularProducts', {
        params: {
          initial: 'popular products',
          expand: 'annotation,specification,images,prices,seo,category,extra,producers',
          'per-page': 10,
          'filter[is_season]': 1,
          'filter[sort_stock]': 1,
        },
      }),
    ])
  },
  // fetchOnServer: false,
  head() {
    return {
      title: this.seo?.title,
      meta: this.$services.head.createMeta({
        title: this.seo?.title,
        description: this.seo?.description,
        keywords: this.seo?.keywords,
      }),
      link: [this.$services.head.createCanonicalLink(this.$route.path)],
    }
  },
  watch: {
    async isAuth(val) {
      if (val === true) {
        await this.$store.dispatch('rph-products/getPersonalRecProducts', {
          params: {
            expand: 'annotation,specification,images,prices,seo,category,extra,producers',
            'per-page': 10,
          },
        })
      }
    },
  },
  computed: {
    isAuth() {
      return this.$auth.loggedIn
    },
    seo() {
      return this.$store.getters['rph-main/mainPageSeo']
    },
    mainPageSeoText() {
      return this.$store.getters['rph-main/mainPageSeoText']
    },
    products() {
      return this.$store.getters['rph-products/products']
    },
    isMobileApp() {
      return this.$store.getters['rph-main/isMobileApp']
    },
    isAuth() {
      return this.$auth.loggedIn
    },
  },
}
